import { styled } from "@linaria/react";
import { WithClassNameProps } from "interfaces";
import React from "react";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 24px;
  padding-left: 7px;
  padding-right: 7px;

  background-color: #ffffff;
  border: 1px solid black;

  line-height: 1;
  font-size: 9px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000000;

`;

const VisitedIndicator = ({ className }: WithClassNameProps) => {
  return (
    <Root className={className}>
      <span>Visited</span>
    </Root>
  );
};

export default VisitedIndicator;
