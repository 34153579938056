import SaveButton from "components/Button/SaveButton";
import {
  useRemoveViewingRoomFromSaved,
  useSaveViewingRoom,
} from "features/PlanYourVisit/hooks/useSaveViewingRoom";
import React, { useCallback } from "react";
import { ViewingRoom } from "../interfaces/viewing-room.interfaces";

type SaveViewingRoomCardActionProps = {
  viewingRoom: ViewingRoom;
  className?: string;
};

const SaveViewingRoomCardAction = ({
  viewingRoom,
  className,
}: SaveViewingRoomCardActionProps) => {
  const { mutate: save } = useSaveViewingRoom();
  const { mutate: unsave } = useRemoveViewingRoomFromSaved();

  const handleClick = useCallback(() => {
    if (viewingRoom.isFollowed) {
      viewingRoom.isFollowed = false
      unsave(viewingRoom);
    } else {
      viewingRoom.isFollowed = true
      save(viewingRoom);
    }
  }, [save, unsave, viewingRoom]);
  return (
    <SaveButton
      className={className}
      active={viewingRoom.isFollowed}
      onClick={handleClick}
    />
  );
};

export default SaveViewingRoomCardAction;
