import { styled } from "@linaria/react";
import { WithClassNameProps } from "interfaces";
import React from "react";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 72px;
  height: 24px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 6px;

  background-color: #ffffff;
  border: 1px solid #000000;

  line-height: 1;
  font-size: 9px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000000;

  &::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3dd800;
    align-self: center;
    flex-shrink: 0;
  }
`;

const LiveIndicator = ({ className }: WithClassNameProps) => {
  return (
    <Root className={className}>
      <span>Live Chat</span>
    </Root>
  );
};

export default LiveIndicator;
