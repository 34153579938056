import { SearchTemplateItem } from "features/SearchTemplate/search-template.interfaces";
import { sponsorsSorter } from "features/ViewingRoom/hooks/useSponsors";
import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import { api } from "index";
import { useEffect, useMemo } from 'react';
import { useQuery, UseQueryOptions } from "react-query";
import shuffleArray from "utils/shuffleArray";
import {
  FairContent,
  HomePageData,
  isFairContent,
  isSearchTemplate,
  isSectionContent,
  isSponsoredContent,
  SectionContent
} from "../interfaces/home.interfaces";
import { useLocation } from 'react-router-dom';

const homeContentRequest = () => {
  return api.get(`/api/v1/user/main-screen`).json<HomePageData>();
};

export const sponsoredDescriptions: Record<string, string> = {
  "281": `Deutsche Bank is proud to be Frieze’s Global Lead Partner for the 17th consecutive year.
    In a time of global upheaval we explore artists from the Deutsche Bank Collection who speak
    out on behalf of different societal causes`,
  "282": `Deutsche Bank is proud to be Frieze’s Global Lead Partner for the 17th consecutive year.
    In a time of global upheaval we explore artists from the Deutsche Bank Collection who speak
    out on behalf of different societal causes`,
  "280":
    "For 2020 BMW Open Work, Madeline Hollander presents energy cycles in an interactive digital platform as well a livery intervention during Frieze Week in London 2020. A live, site-specific installation will be presented at Frieze Los Angeles 2021.",
};

export const HOME_PAGE_QUERY_KEY = "home-page-data";

export default function useHomePageContent(
  config?: UseQueryOptions<HomePageData>
) {
  const {pathname} = useLocation()
  const { data, refetch, ...rest } = useQuery(
    [HOME_PAGE_QUERY_KEY],
    homeContentRequest,
    {
      staleTime: 15 * 60 * 1000,
      ...config,
    }
  );


  const computedData = useMemo(() => {
    let specialProjects: SectionContent[] = [],
      sponsored: ViewingRoom[] = [],
      fairs: FairContent[] = [],
      searchTemplates: SearchTemplateItem[] = [];

    if (!data) {
      return { specialProjects, sponsored, fairs };
    }
    data.forEach((item) => {
      if (isFairContent(item)) {
        item.sections.forEach((section) => {
          section.viewingRooms.forEach((vr) => {
            vr.Section = section.Section;
            vr.Section.Fair = item.Fair;
          });
          if (section.Section.code === "SS") {
            section.viewingRooms = section.viewingRooms.sort(sponsorsSorter);
            console.log(section.viewingRooms);
          }
        });
        fairs.push(item);
      } else if (
        isSectionContent(item) &&
        item.viewingRooms.length &&
        (item as any).fairId === null
      ) {
        specialProjects.push(item);
      } else if (isSearchTemplate(item)) {
        searchTemplates.push(...item.searchTemplates);
      } else if (isSponsoredContent(item)) {
        sponsored.push(...item.viewingRooms.sort(sponsorsSorter));
      }
    });

    return { specialProjects, sponsored, fairs, searchTemplates };
  }, [data]);

  const shuffledTemplates = useMemo(() => {
    const templates = computedData.searchTemplates;
    if (templates && templates.length > 3) {
      return shuffleArray(templates).slice(0, 3);
    }
    return templates;
  }, [computedData.searchTemplates]);
  useEffect(() => {
    refetch()
  }, [pathname, refetch])

  return { data, computedData, shuffledTemplates, templates: computedData.searchTemplates, ...rest };
}
