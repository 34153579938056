import React from "react";
import SignTheBookButton from "components/Button/SignTheBookButton";
import { ViewingRoom } from "../interfaces/viewing-room.interfaces";
import {ViewingRoomInSection} from "../../ServerConstants/store/serverConstants";

type SignTheBookCardActionProps = {
	viewingRoom: ViewingRoom;
  className?: string;
  showTitle?: boolean;
};

const SignTheBookCardAction = ({ viewingRoom, className }: SignTheBookCardActionProps) => {
  return <SignTheBookButton className={className} viewingRoom={viewingRoom} />;
};

export default SignTheBookCardAction;
