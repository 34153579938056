import { styled } from "@linaria/react";
import { up } from "styles/utils/breakpoints";

const ArtworksGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  grid-row-gap: 40px;

  ${up("sm")} {
    grid-column-gap: 17px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  ${up("md")} {
    grid-column-gap: 17px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  ${up("xlg")} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    padding-bottom: 40px;
    grid-column-gap: 17px;
    grid-row-gap: 67px;
  }
`;

export default ArtworksGrid;
