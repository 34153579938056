import { Artwork } from "features/Artwork/artwork.interfaces";
import { api } from "index";
import { ViewingRoom } from "../interfaces/viewing-room.interfaces";

export const followViewingRoomRequest = (vr: ViewingRoom) => {
  return api.post(`/api/v1/user/viewing-rooms/${vr.id}/follow`);
};

export const unFollowViewingRoomRequest = (vr: ViewingRoom) => {
  return api.delete(`/api/v1/user/viewing-rooms/${vr.id}/follow`);
};

export const followArtworkRequest = (artwork: Artwork) => {
  return api.post(`/api/v1/user/artworks/${artwork.id}/follow`);
};

export const unFollowArtworkRequest = (artwork: Artwork) => {
  return api.delete(`/api/v1/user/artworks/${artwork.id}/follow`);
};
