import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ArtworkContextNavigationMode,
  SectionContextNavigationMode,
  setArtworkMode as setArtworkModeAction,
  setSectionMode as setSectionModeAction,
  setViewingRoomMode as setViewingRoomModeAction,
  ViewingRoomContextNavigationMode,
} from "../store/contextNavigationSlice";

type Props = {
  sectionMode?: SectionContextNavigationMode
  viewingRoomMode?: ViewingRoomContextNavigationMode;
  artworkMode?: ArtworkContextNavigationMode;
};

export default function useContextNavigation({
  sectionMode,
  viewingRoomMode,
  artworkMode,
}: Props) {
  const dispatch = useDispatch();

  const setSectionMode = useCallback(() => {
    const fn = setSectionModeAction({
      mode: !!sectionMode ? sectionMode : null,
    })

    dispatch(fn)
  }, [dispatch, sectionMode])
  const setViewingRoomMode = useCallback(() => {
    const fn = setViewingRoomModeAction({
      mode: !!viewingRoomMode ? viewingRoomMode : null,
    })

    dispatch(fn);
  }, [dispatch, viewingRoomMode]);
  const setArtworkMode = useCallback(() => {
    const fn = setArtworkModeAction({
      mode: !!artworkMode ? artworkMode : null,
    })

    dispatch(fn);
  }, [dispatch, artworkMode]);

  return { setViewingRoomMode, setArtworkMode, setSectionMode };
}
