import { ContextNavigationDesktop } from "features/ContextNavigation/ContextNavigation/ContextNavigationDesktop";
import { ContextNavigationMobile } from "features/ContextNavigation/ContextNavigation/ContextNavigationMobile";
import React from "react"
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";
import { SpecialSection } from "../special.interfaces";
import { specialSectionRoute } from "../special.routes";

type ContextProps = {
  section?: SpecialSection
}

const SpecialSectionContextNavigation = ({section}: ContextProps) => {
  const upLg = useBreakpoint(up('lg'))

  return upLg ? (
    <ContextNavigationDesktop
      prevLink={null}
      nextLink={null}
      meta={[
        { name: 'Home', link: () => '/' },
        { name: section?.name || "", link: specialSectionRoute.path}
      ]}
      type="section"
    />
  ): (
    <ContextNavigationMobile
      prevLink={null}
      nextLink={null}
      title={section?.name || ""}
      type="section"
    />
  )
}

export default SpecialSectionContextNavigation