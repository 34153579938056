import React from "react"
import { styled } from "@linaria/react";
import { spacerMixin } from "components/BaseSpacer";
import Footer from "components/Footer/Footer";
import RootThemedLayout from "components/RootThemedLayout";
import HeaderSwitch from "features/Header/HeaderSwitch";
import { up } from "styles/utils/breakpoints";
import ArtworksGrid from "features/Artwork/components/ArtworksGrid";
import SlideAndFadeOnScroll from "components/Animations/SlideAndFadeOnScroll/SlideAndFadeOnScroll";
import SpecialSectionContextNavigation from "../components/SpecialSectionContextNavigation";
import SpecialSectionHeadline from "../components/SpecialSectionHeadline";
import { useSpecialSection } from "../hooks/useSpecialSection";
import ViewingRoomCard from "features/ViewingRoom/components/ViewingRoomCard";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${spacerMixin}
  ${up("lg")} {
    padding-bottom: 58px;
  }
`;


const StyledArtworksGrid = styled(ArtworksGrid)`
  margin-top: 30px;
  
  ${up("md")} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  ${up("lg")} {
    grid-column-gap: 30px;
  }
  ${up("xlg")} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const SpecialSectionView = () => {
  const { data } = useSpecialSection();

  return (
    <RootThemedLayout>
      <HeaderSwitch />
      <SpecialSectionContextNavigation section={data?.Section}/>
      <Content>
        <SpecialSectionHeadline section={data?.Section}/>
        <StyledArtworksGrid>
          {data?.viewingRooms.map((item) => (
            <SlideAndFadeOnScroll key={item.id}>
              <ViewingRoomCard 
                key={item.id} 
                item={item} 
                mode="section"
                />
            </SlideAndFadeOnScroll>
          ))}
        </StyledArtworksGrid>

      </Content>
      <Footer />
    </RootThemedLayout>
  )
}

export default SpecialSectionView