import { FairItem } from "features/FairPage/interfaces/fair.interfaces";
import { SearchTemplateItem } from "features/SearchTemplate/search-template.interfaces";
import { Section } from "features/Sections/interfaces/section.interfaces";
import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";

export type HomePageData = Array<Content>;

export interface FairContent {
  fairId: number;
  Fair: FairItem;
  sections: SectionContent[];
}

export interface SectionContent {
  sectionId: number;
  Section: Section;
  viewingRooms: ViewingRoom[];
}

export interface SponsoredContent {
  isSponsor: boolean;
  viewingRooms: ViewingRoom[];
}

export interface SearchTemplateContent {
  isSearchTemplates: true;
  searchTemplates: SearchTemplateItem[];
}

type Content =
  | FairContent
  | SectionContent
  | SponsoredContent
  | SearchTemplateContent;

export function isFairContent(input: Content): input is FairContent {
  return typeof (input as any).fairId === "number";
}

export function isSectionContent(input: Content): input is SectionContent {
  return typeof (input as any).sectionId === "number";
}

export function isSponsoredContent(input: Content): input is SponsoredContent {
  return !!(input as any).isSponsor;
}

export function isSearchTemplate(
  input: Content
): input is SearchTemplateContent {
  return !!(input as SearchTemplateContent).isSearchTemplates;
}
