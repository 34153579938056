import useViewingRoomId from "features/ViewingRoom/hooks/useViewingRoomId";
import { api } from "index";
import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query";
import { SPECIAL_SECTION_CACHE_KEY } from "../api/special-sections.api";
import { ViewingRoomsBySection } from "../special.interfaces";

const fetchSpecialSectionRequest = (id?: string) => {
  return api
    .get(`/api/v1/user/viewing-rooms-by-section/${id}`)
    .json<ViewingRoomsBySection>()
}

export const useSpecialSection = (
  id?: string | number, 
  config?: UseQueryOptions<ViewingRoomsBySection>
) => {
  const currentVrId = useViewingRoomId();

  id = id || currentVrId;

  const result = useQuery(
    [SPECIAL_SECTION_CACHE_KEY, id.toString()], 
    () => fetchSpecialSectionRequest(id?.toString()), 
    {
      enabled: typeof config?.enabled === "boolean" ? config.enabled : !!id,
      staleTime: 15 * 60 * 1000,
      ...config,
    }
  )

  return { ...result, id }
}