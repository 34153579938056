import React, { ComponentType, useMemo } from "react";
import { AdaptiveImageSources } from "./adaptive-img.preview";

const defautSourcesSizes: Record<keyof AdaptiveImageSources, string> = {
  lg: "1600w",
  md: "1200w",
  sm: "700w",
};

export interface AdaptiveImageProps<Sources extends AdaptiveImageSources>
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  sources: Partial<Sources>;
  sourcesSizes?: Record<keyof Sources, string>;
  Component?: "img" | ComponentType<React.ImgHTMLAttributes<HTMLImageElement>>;
}
function AdaptiveImage<
  Sources extends AdaptiveImageSources = AdaptiveImageSources
>({
  sources,
  sourcesSizes,
  Component = "img",
  ...rest
}: AdaptiveImageProps<Sources>) {
  const srcset = useMemo(() => {
    return Object.entries(sources)
      .map(([key, image]) => {
        const size = sourcesSizes
          ? sourcesSizes[key as keyof Sources]
          : defautSourcesSizes[key as keyof AdaptiveImageSources] || "100vw";
        return `${image} ${size}`;
      })
      .join(",");
  }, [sources, sourcesSizes]);
  return <Component {...rest} src={sources.lg} alt="" srcSet={srcset} />;
}

export default AdaptiveImage;
