import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import NavigationLink from "features/Navigation/components/NavigationLink";
import { cover } from "polished";

export const cardLink = css`
  &::after {
    z-index: 1;
    content: "";
    ${cover()}
  }
`;

const CardLink = styled(NavigationLink)`
  &::after {
    content: "";
    ${cover()}
  }
`;

export default CardLink;
