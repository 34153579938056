import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import classes from "./SlideAndFadeOnScroll.module.scss";

type SlideOnScrollProps = React.PropsWithChildren<
  React.ComponentProps<typeof ScrollAnimation>
>;

const SlideAndFadeOnScroll = (props: SlideOnScrollProps) => {
  return (
    <ScrollAnimation
      animateOnce
      animateIn={classes.slideAndFade}
      delay={0}
      duration={0.5}
      offset={100}
      {...props}
    />
  );
};

export default SlideAndFadeOnScroll;
