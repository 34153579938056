import {
  Breakpoint,
  resolveDownBp,
  sortValuesByBp,
} from "styles/utils/breakpoints";

type SizesMap = Partial<Record<Breakpoint, string>>;
export default function getImgSizes(map: SizesMap) {
  const sortedValues = sortValuesByBp(
    Object.entries(map) as [Breakpoint, string][]
  );

  return sortedValues
    .map(([bp, value], index) => {
      if (index === sortValuesByBp.length - 1) {
        return value;
      }
      return `(max-width: ${resolveDownBp(bp)}) ${value}`;
    })
    .join(",");
}
