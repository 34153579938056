import useViewingRoom from "features/ViewingRoom/hooks/useViewingRoom";
import { useMemo } from "react";
import shuffleArray from "utils/shuffleArray";

const server = (process.env as any).REACT_APP_SERVER;

export const SpecialSectionId =
  server === "dev"
    ? 25
    : server === "staging"
    ? 20
    : server === "production"
    ? 28
    : undefined;

export default function useHardcodedVR() {
  const { data, ...rest } = useViewingRoom(SpecialSectionId, {
    enabled: !!SpecialSectionId,
  });

  const newData = useMemo(() => {
    if (!data) {
      return data;
    }
    return {
      ...data,
      artworks: shuffleArray(data.artworks),
    };
  }, [data]);
  return { ...rest, data: newData };
}
