import {
  AdaptiveImageSources,
  AdaptivePreview,
  BestAdaptivePreview,
} from "../adaptive-img.preview";

export default function getImageSources<
  T extends AdaptivePreview | (AdaptivePreview & BestAdaptivePreview)
>(item: T): Partial<AdaptiveImageSources> {
  return {
    lg:
      (item as BestAdaptivePreview).bestPreviewOriginal || item.previewOriginal,
    sm: (item as BestAdaptivePreview).bestPreviewMobile || item.previewMobile,
    md: (item as BestAdaptivePreview).bestPreviewDesktop || item.previewDesktop,
  };
}

export function getImageOptimizedSources<
  T extends AdaptivePreview | (AdaptivePreview & BestAdaptivePreview)
>(item: T, best?: boolean): Partial<AdaptiveImageSources> {
  const itemWithBest = item as BestAdaptivePreview;
  return {
    sm:
      best && itemWithBest.bestPreviewMobile
        ? itemWithBest.bestPreviewMobile
        : item.previewMobile,
    md:
      best && itemWithBest.bestPreviewDesktop
        ? itemWithBest.bestPreviewDesktop
        : item.previewDesktop,
  };
}
