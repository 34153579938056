import clsx from "clsx";
import { cardLink } from "components/CardLink";
import useContextNavigation from "features/ContextNavigation/hooks/useContextNavigation";
import { ViewingRoomContextNavigationMode } from "features/ContextNavigation/store/contextNavigationSlice";
import NavigationLink, {
  NavigationLinkProps
} from "features/Navigation/components/NavigationLink";
import React, { useCallback } from "react";

type ArtworkNavigationLinkProps = {
  mode?: ViewingRoomContextNavigationMode;
} & NavigationLinkProps;

const ViewingRoomNavigationLink = ({
  onClick,
  mode: viewingRoomMode,
  className,
  ...rest
}: ArtworkNavigationLinkProps) => {
  const { setViewingRoomMode } = useContextNavigation({ viewingRoomMode });

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      viewingRoomMode && setViewingRoomMode();
      onClick && onClick(e);
    },
    [onClick, setViewingRoomMode, viewingRoomMode]
  );
  return (
    <NavigationLink
      {...rest}
      className={clsx(className, cardLink)}
      onClick={handleClick}
    />
  );
};

export default ViewingRoomNavigationLink;
