import React from "react"
import { styled } from "@linaria/react";
import { secondaryFont } from "styles";
import { up, down } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import fluid from "styles/utils/fluid";
import responsive from "styles/utils/responsive";
import { SpecialSection } from "../special.interfaces";

const Headline = styled.div`
  display: flex;

  ${responsive("padding-top", {
    xs: "24px",
    lg: "0px",
  })}

  ${up("lg")} {
    border-bottom: 1px solid rgba(171,171,171,0.41);
    ${fluid("min-height", {
      lg: "288px",
      xlg: "288px",
      xl: "360px",
    })};
    padding-block: 60px;
  }
  
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;

  text-align: center;
`;

const Title = styled.h1`
  ${fluid("font-size", {
    xs: "27px",
    lg: "40px",
  })}
  line-height: ${em("48px", "40px")};
  text-transform: uppercase;

  ${down("lg")} {
    text-align: center;
  }
`;


const Description = styled.p`
  ${up("lg")} {
    ${fluid("max-width", {
      lg: "840px",
      xlg: "880px",
      xl: "1110px",
    })}
  }
  ${fluid("margin-top", {
    xs: "13px",
    lg: "13px",
    xlg: "16px",
    xl: "16px"
  })}

  ${fluid("font-size", {
    xs: "16px",
    lg: "20px",
  })}
  line-height: 1.3;
  ${responsive("letter-spacing", {
    xs: em("0.13px", "16px"),
    lg: em("0.3px", "26px"),
  })}
  font-family: ${secondaryFont};
  text-align: center;
`;

type HealineProps = {
  section?: SpecialSection
}

const SpecialSectionHeadline = ({section}: HealineProps) => (
  <Headline>
    <TitleWrapper>
      {section && (
        <>
          <Title>{section.name}</Title>
          <Description>{section.description}</Description>
        </>
      )}
    </TitleWrapper>
  </Headline>
)

export default SpecialSectionHeadline