import { styled } from "@linaria/react";
import clsx from "clsx";
import { BookmarkFilled, BookmarkOutline } from "components/icons";
import { svgCss } from "components/Svg";
import React from "react";
import { down } from "styles/utils/breakpoints";

const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 10;

  flex-shrink: 0;
  font-size: 14px;
  color: #000000;
  ${down("sm")} {
    font-size: 18px;
  }
  .dark & {
    color: rgba(255, 255, 255, 1);
  }

  p {
    margin-left: 8px;
    font-size: 14px;
  }
  

  &.filled path {
    stroke: none;
  }
`;

interface SaveButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
  active: boolean;
  showTitle?: boolean;
}

const SaveButton = ({ className, active, showTitle = false, ...props }: SaveButtonProps) => {
  return (
    <Button {...props} className={clsx(className, svgCss)}>
      {!!active && (
        <React.Fragment>
          <BookmarkFilled />
          { showTitle && <p>Saved</p> }
        </React.Fragment>
       )}
       
       {!active && (
         <React.Fragment>
          <BookmarkOutline />
          { showTitle && <p>Save</p> }
         </React.Fragment>
       )}
    </Button>
  );
};

export default SaveButton;
