import { DefaultListResponse } from "features/api/api.interfaces";
import { ArtworkItem } from "features/Artwork/artwork.interfaces";
import { PaginationInfo } from "features/Pagination/interfaces/pagination.interfaces";
import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import { api } from "index";
import { stringify } from "query-string";
import { SearchFiltersSource } from "../interfaces/search.interfaces";

export const artworksSearchRequests = (
  params: SearchFiltersSource & PaginationInfo
) => {
  return api
    .get(`/api/v1/user/artworks?${stringify(params, { arrayFormat: "comma" })}`)
    .json<DefaultListResponse<ArtworkItem>>();
};

export const viewingRoomsSearchRequests = (
  params: SearchFiltersSource & PaginationInfo
) => {
  return api
    .get(
      `/api/v1/user/viewing-rooms?${stringify(params, {
        arrayFormat: "comma",
      })}`
    )
    .json<DefaultListResponse<ViewingRoom>>();
};
