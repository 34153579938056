import { styled } from "@linaria/react";
import RatioWrapperBase from "components/RatioWrapper/RatioWrapperBase";
import React from "react";

export type CardProps = {
  image?: React.ReactChild;
  primaryText: React.ReactChild;
  secondaryText?: React.ReactChild;
  action?: React.ReactChild;
  className?: string;
};

const PrimaryWrapperr = styled.div`
  position: relative;

  & img {
    max-width: 100%;
  }
`;

const PrimaryTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const ActionWrapper = styled.div`
  margin-left: 10px;
  position: relative;
  flex-shrink: 0;
  z-index: 10;
`;

const Card = ({
  action,
  image,
  primaryText,
  secondaryText,
  className,
}: CardProps) => {
  return (
    <div className={className}>
      <PrimaryWrapperr className="primary-wrapper">
        {image && (
          <RatioWrapperBase className="image-wrapper">{image}</RatioWrapperBase>
        )}
        <PrimaryTextWrapper className="primary-text-wrapper">
          {primaryText}
          {action && (
            <ActionWrapper className="action-wrapper">{action}</ActionWrapper>
          )}
        </PrimaryTextWrapper>
      </PrimaryWrapperr>
      <div className="secondary-text">{secondaryText}</div>
    </div>
  );
};

export default Card;
