import Analytics from "features/Analytics/Analytics";
import { AnalyticsClientProvider } from "features/Analytics/AnalyticsClientProvider";
import { artworkRouteConfig } from "features/Artwork/artwork.routes";
import useObserveAccess from "features/Auth/hooks/useObserveAccess";
import useSelfInfo from "features/Profile/hooks/useSelfInfo";
import useSelfAccess from "features/Auth/hooks/useSelfAccess";
import ChatView from "features/Chat/ChatView";
import ChatProvider from "features/Chat/components/ChatProvider";
import ClosedView from "features/Closed/ClosedView";
import { faqRoute } from "features/FAQ/faq.routes";
import FAQ from "features/FAQ/FAQView/FAQ";
import { homeRouteConfig } from "features/Home/home.routes";
import HomepageView from "features/Home/HomepageView";
import { fairRoute } from "features/FairPage/fair.routes";
import useNext from "features/Next/hooks/useNext";
import { meRoute } from "features/Profile/profile.routes";
import { searchRoute } from "features/Search/search.routes";
import { sectionRoute } from "features/Sections/section.routes";
import { viewingRoomRoutes } from "features/ViewingRoom/viewing-room.routes";
import useMoveTo from "hooks/useMoveTo";
import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import viewingRoomStorage from "utils/viewingRoomsStorage";
// import { nftRoutes } from "features/Nft/nft.routes";
import FairView from "features/FairPage";
import { specialSectionRoute } from "features/SpecialSection/special.routes";
import SpecialSectionView from "features/SpecialSection/view/SpecialSectionView";
import { SpecialSectionId } from "features/Home/hooks/useHardcodedVR";

const WaitingRoomCountent = React.lazy(() => import("./WaitingRoomContent"));
const ProfileView = React.lazy(() => import("features/Profile/ProfileView/ProfileView"));
const ViewingRoomView = React.lazy(() => import("features/ViewingRoom/view/ViewingRoomView"));
const ArtDetailView = React.lazy(() => import("features/Artwork/ArtDetailView"));
const SearchView = React.lazy(() => import("features/Search/view/SearchView"));
const SectionView = React.lazy(() => import("features/Sections/SectionView/SectionView"));
// const NftView = React.lazy(() => import("features/Nft/view/NftView"));

const nextStopWords = ["moveTo"];

const UserContent = () => {
  useObserveAccess();
  const { accessType } = useSelfAccess();
  const { data } = useSelfInfo();
  const { search } = useLocation();
  useMoveTo();
  useNext({ stop: nextStopWords });
  useEffect(() => {
    if (!data?.visitedRooms) return;
    viewingRoomStorage.setIds(data?.visitedRooms);
  }, [data]);

  return (
    <Suspense fallback={null}>
      {accessType === "not-start" ? (
        <WaitingRoomCountent />
      ) : accessType === "closed" ? (
        <ClosedView />
      ) : accessType === "started" ? (
        <AnalyticsClientProvider>
          <ChatProvider>
            <Analytics />
            <Switch>
              <Route path={searchRoute.path} component={SearchView} />
              <Route path={meRoute.path} component={ProfileView} />
              /!* <Redirect path={`/section/${SpecialSectionId}`} to={specialSectionRoute.path} /> *!/
              <Route path={sectionRoute.path} component={SectionView} />
              <Route path={faqRoute.path} exact component={FAQ} />
              <Route path={fairRoute.path} exact component={FairView} />
              <Route path={artworkRouteConfig.path} component={ArtDetailView} />
              /!* <Route path={specialSectionRoute.path} component={SpecialSectionView} /> *!/
              <Route path={viewingRoomRoutes.path} component={ViewingRoomView} />
              <Route path={homeRouteConfig.link} component={HomepageView} />
              <Redirect exact to={`${homeRouteConfig.link}${search}`} />
            </Switch>

            <ChatView />
          </ChatProvider>
        </AnalyticsClientProvider>
      ) : null}
    </Suspense>
  );
};

export default UserContent;
