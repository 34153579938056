import { DefaultListResponse } from "features/api/api.interfaces";
import {
    ARTWORK_CACHE_KEY,
    ARTWORK_CACHE_KEY_INFINITE
} from "features/Artwork";
import { Artwork, ArtworkItem } from "features/Artwork/artwork.interfaces";
import {
    VIEWING_ROOM_CACHE_KEY,
    VIEWING_ROOM_CACHE_KEY_INFINITE
} from "features/ViewingRoom/api.viewingroom";
import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import {
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    useQuery,
    UseQueryOptions
} from "react-query";
import artworksStorage from "utils/artworksStorage";
import viewingRoomStorage from "utils/viewingRoomsStorage";
import {
    artworksSearchRequests,
    viewingRoomsSearchRequests
} from "../api/search.api.";
import { SearchFilters } from "../interfaces/search.interfaces";
import useSearchState from "./useSearchState";

export function formatFiltersForRequest(filters: Partial<SearchFilters>) {
  return {
    ...filters,
    artists: filters.artists?.id,
  };
}

export function useArtworksFilters() {
  const searchState = useSearchState();
  return {
    ...searchState,
    artists: searchState.artists?.id,
  };
}

export function useArtworks(
  config?: UseQueryOptions<DefaultListResponse<Artwork>>
) {
  const filters = useArtworksFilters();
  const artworksIds = artworksStorage.getIds()
  return useQuery(
    [ARTWORK_CACHE_KEY, filters, artworksIds],
    () => artworksSearchRequests(filters),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000 * 5,
      ...config,
    }
  );
}

export function useArtworksQueryInfinite() {
  const { skip, ...searchState } = useSearchState();
  return {
    ...searchState,
    artists: searchState.artists?.id,
  };
}

export function useArtworksInfinite(
  config?: UseInfiniteQueryOptions<DefaultListResponse<ArtworkItem>>
) {
  const filters = useViewingroomsQueryInfinite();
  const limit = filters.limit;
  const artworksIds = artworksStorage.getIds()
  return useInfiniteQuery(
    [ARTWORK_CACHE_KEY_INFINITE, filters],
    ({ pageParam }: any) => {
      const skip = pageParam ?? 0;
      return artworksSearchRequests({ ...filters, skip });
    },
    {
      staleTime: 60 * 1000 * 5,
      getNextPageParam(last, allPages) {
        if (allPages.length * limit < last.count) {
          return allPages.length * limit;
        }
      },
      ...config,
    }
  );
}

export function useViewingroomsFilters() {
  const searchState = useSearchState();
  return {
    ...searchState,
    artists: searchState.artists?.id,
  };
}

export function useViewingRooms(
  config?: UseQueryOptions<DefaultListResponse<ViewingRoom>>
) {
  const veiwingRoomsFilters = useViewingroomsFilters();
  const viewingRoomsIds = viewingRoomStorage.getIds();
  return useQuery(
    [VIEWING_ROOM_CACHE_KEY, veiwingRoomsFilters, viewingRoomsIds],
    () => viewingRoomsSearchRequests(veiwingRoomsFilters),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000 * 5,
      ...config,
    }
  );
}

export function useViewingroomsQueryInfinite() {
  const { skip, ...searchState } = useSearchState();
  return {
    ...searchState,
    artists: searchState.artists?.id,
  };
}

export function useViewingRoomsInfinite(
  config?: UseInfiniteQueryOptions<DefaultListResponse<ViewingRoom>>
) {
  const viewingRoomsIds = viewingRoomStorage.getIds();
  const viewingRoomsFilters = useViewingroomsQueryInfinite();
  const { limit } = viewingRoomsFilters;
  return useInfiniteQuery(
    [VIEWING_ROOM_CACHE_KEY_INFINITE, viewingRoomsFilters, viewingRoomsIds],
    ({ pageParam }: any) => {
      const skip = pageParam ?? 0;
      return viewingRoomsSearchRequests({ ...viewingRoomsFilters, skip });
    },
    {
      staleTime: 60 * 1000 * 5,
      getNextPageParam(last, allPages) {
        if (allPages.length * limit < last.count) {
          return allPages.length * limit;
        }
      },
      ...config,
    }
  );
}

export function useSearchInfiniteQueries(
  vrConfig?: UseInfiniteQueryOptions<DefaultListResponse<ViewingRoom>>,
  artConfig?: UseInfiniteQueryOptions<DefaultListResponse<ArtworkItem>>
) {
  const artworksFilters = useArtworksFilters();
  const veiwingRoomsFilters = useViewingroomsFilters();
  const artworksIds = artworksStorage.getIds()
  const artworksQuery = useInfiniteQuery(
    [ARTWORK_CACHE_KEY_INFINITE, artworksFilters, artworksIds],
    () => artworksSearchRequests(artworksFilters),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000 * 5,
      ...artConfig,
    }
  );
  const viewingRoomsIds = viewingRoomStorage.getIds();
  const viewingRoomsQuery = useInfiniteQuery(
    [VIEWING_ROOM_CACHE_KEY_INFINITE, veiwingRoomsFilters, viewingRoomsIds],
    () => viewingRoomsSearchRequests(veiwingRoomsFilters),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000 * 5,
      ...vrConfig,
    }
  );

  return { artworksQuery, viewingRoomsQuery };
}

export default function useSearchQueries(
  vrConfig?: UseQueryOptions<DefaultListResponse<ViewingRoom>>,
  artConfig?: UseQueryOptions<DefaultListResponse<ArtworkItem>>
) {
  const artworksFilters = useArtworksFilters();
  const veiwingRoomsFilters = useViewingroomsFilters();
  const artworksIds = artworksStorage.getIds()
  const artworksQuery = useQuery(
    [ARTWORK_CACHE_KEY, artworksFilters, artworksIds],
    () => artworksSearchRequests(artworksFilters),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000 * 5,
      ...artConfig,
    }
  );
  const viewingRoomsIds = viewingRoomStorage.getIds();
  const viewingRoomsQuery = useQuery(
    [VIEWING_ROOM_CACHE_KEY, veiwingRoomsFilters, viewingRoomsIds],
    () => viewingRoomsSearchRequests(veiwingRoomsFilters),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000 * 5,
      ...vrConfig,
    }
  );

  return { artworksQuery, viewingRoomsQuery };
}
