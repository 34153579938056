import { Artwork, ArtworkItem } from "features/Artwork/artwork.interfaces";
import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import { api } from "index";
import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { normalizeDataWthId } from "utils/normalizeDataWithId";

type ResponseType = {
  artworks: ArtworkItem[];
  viewingRooms: ViewingRoom[];
};

const fetchFollowsRequest = () => {
  return api.get(`/api/v1/user/follows`).json<ResponseType>();
};

export const FOLLOWS_CACHE_KEY = "follows";

export default function useFollows(config?: UseQueryOptions<ResponseType>) {
  const { data, ...rest } = useQuery(
    [FOLLOWS_CACHE_KEY],
    fetchFollowsRequest,
    config
  );

  const computedData = useMemo(() => {
    const viewingRooms: Record<string, ViewingRoom> = data?.viewingRooms
      ? normalizeDataWthId(data.viewingRooms).itemsMap
      : {};
    const artworks: Record<string, Artwork> = data?.artworks
      ? normalizeDataWthId(data.artworks).itemsMap
      : {};

    return { viewingRooms, artworks };
  }, [data]);

  return { ...rest, data, computedData };
}
