import { api } from "index";
import { useQuery, UseQueryOptions } from "react-query";
import { useParams } from "react-router-dom";
import { FairResponse } from "../interfaces/fair.interfaces";

const fetchFairRequest = (id?: number | string) => {
  return api.get(`/api/v1/user/after-fair/${id}`).json<FairResponse>();
};

export const FAIR_QUERY_KEY = "fair";

export default function useFair(
  id?: string,
  config?: UseQueryOptions<FairResponse>
) {
  const { fairId } = useParams<{ fairId: string }>();
  id = id || fairId;

  const { data, ...rest } = useQuery(
    [FAIR_QUERY_KEY, id],
    () => fetchFairRequest(id),
    config
  );

  const computedData = !data
    ? undefined
    : {
        ...data,
        sections: data.sections.map((section) => ({
          Section: section.Section,
          viewingRooms: [...section.promoted, ...section.notPromoted].map(
            (item) => ({
              ...item,
              Section: {
                ...item.Section,
                Fair: data.Fair,
              },
            })
          ),
        })),
      };

  //Memoization doesn't work for some reason
  // const computedData: FairComputed | undefined = useMemo(() => {
  //   if (!data) {
  //     return;
  //   }
  //   return {
  //     ...data,
  //     sections: data.sections
  //       .sort((a, b) => {
  //         return sectionsOrder[a.Section.id] - sectionsOrder[b.Section.id];
  //       })
  //       .map((section) => ({
  //         Section: section.Section,
  //         viewingRooms: [...section.promoted, ...section.notPromoted].map(
  //           (item) => ({
  //             ...item,
  //             Section: {
  //               ...item.Section,
  //               Fair: data.Fair,
  //             },
  //           })
  //         ),
  //       })),
  //   };
  // }, [data]);

  return { ...rest, data, computedData, id };
}
