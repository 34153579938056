import { QueryClient, QueryKey } from "react-query";
import { Updater } from "react-query/types/core/utils";

export default async function optimisticHandler<T>(
  key: QueryKey,
  updater: Updater<T | undefined, T | undefined>,
  queryClient: QueryClient
) {
  await queryClient.cancelQueries(key);

  // Snapshot the previous value
  const previousValue = queryClient.getQueryData<T>(key);
  if (previousValue) {
    queryClient.setQueryData<T>(key, updater as any);
  }
  // Optimistically update to the new value

  // Return the snapshotted value
  return { previousValue };
}
