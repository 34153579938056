import * as React from "react";

const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={24} viewBox="0 0 1920 1920" {...props}>
    <path fill="currentColor" d="M1150.6,1320.3l214.1-586.8,260.4-185.8L1366.9,289.4l-22.3-21.1L1141.3,64.9,913.2,327.4,358.8,529.6,72.2,1515.3c-6.4,19.8-14.1,49.6-14.5,50.8l-19.4,74.7,125.2-33.4Zm-4.7-1123.5,135.5,135.5,22.2,21.2,182.7,182.7L1328.6,648.7,1102.1,422.2l-.5.6-81.4-81.3ZM432.5,598.6,958.3,406.8l144.9,145,.6-.6,169.7,169.7-191.8,525.8L306.5,1472.2,925.1,817.7l35.2-37.4-63.6-63.6-38.1,35.8L209.3,1366Z" />
    <rect fill="currentColor" x="35.3" y="1719.1" width="1795.7" height="90" />
  </svg>
);

export default EditIcon;
