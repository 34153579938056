import { styled } from "@linaria/react";
import clsx from "clsx";
import { svgCss } from "components/Svg";
import React, { useCallback } from "react";
import { down } from "styles/utils/breakpoints";
import useSignTheBookBar from "features/SignTheBook/hooks/useSighTheBookBar";
import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import EditIcon from "components/icons/EditIcon";
import {ViewingRoomInSection} from "../../features/ServerConstants/store/serverConstants";

const CustomButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 10;

  flex-shrink: 0;
  font-size: 16px;
  color: #000000;
  ${down("sm")} {
    font-size: 18px;
  }
  .dark & {
    color: rgba(255, 255, 255, 1);
  }

  p {
    margin-left: 8px;
    font-size: 14px;
  }

  &.filled path {
    stroke: none;
  }
`;

interface SignTheBookButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
  viewingRoom: ViewingRoom;
}

const SignTheBookButton = ({
  className,
  viewingRoom,
  ...props
}: SignTheBookButtonProps) => {
  const { showBar, addViewingRoomId } = useSignTheBookBar();

  const handleSignTheBook = useCallback(() => {
    addViewingRoomId(viewingRoom.id);
    showBar();
  }, [addViewingRoomId, viewingRoom, showBar]);

  return (
    <CustomButton
      {...props}
      className={clsx(className, svgCss)}
      onClick={handleSignTheBook}
    >
      {!viewingRoom.isSigned && (
        <React.Fragment>
          <EditIcon height={24} />
        </React.Fragment>
      )}
    </CustomButton>
  );
};

export default SignTheBookButton;
