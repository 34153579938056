import { DefaultListResponse } from "features/api/api.interfaces";
import { FAIR_QUERY_KEY } from "features/FairPage/hooks/useFair";
import { FairResponse } from "features/FairPage/interfaces/fair.interfaces";
import { HOME_PAGE_QUERY_KEY } from "features/Home/hooks/useHomePageContent";
import {
  HomePageData,
  isFairContent,
  isSectionContent,
} from "features/Home/interfaces/home.interfaces";
import { FOLLOWS_CACHE_KEY } from "features/Profile/hooks/useFollows";
import { FollowResponse } from "features/Profile/interfaces/follow.interfaces";
import {
  useViewingroomsFilters,
  useViewingroomsQueryInfinite,
} from "features/Search/hooks/useSearchQueryies";
import { useSnackbar } from "features/Snackbar/SnackbarProvider";
import {
  VIEWING_ROOM_CACHE_KEY,
  VIEWING_ROOM_CACHE_KEY_INFINITE,
} from "features/ViewingRoom/api.viewingroom";
import {
  followViewingRoomRequest,
  unFollowViewingRoomRequest,
} from "features/ViewingRoom/hooks/useFollowViewingRoomMutations";
import {
  ViewingRoom,
  ViewingRoomItem,
} from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import optimisticHandler from "utils/optimisticHandler";

function setFollowForList(followed: ViewingRoom, list: ViewingRoom[]) {
  return list.map((item) => {
    if (item.id === followed.id) {
      item.isFollowed = !item.isFollowed;
    }
    return item;
  });
}

type ContextFunctions = Array<() => void> | undefined;
type Options = UseMutationOptions<
  Response,
  unknown,
  ViewingRoom,
  ContextFunctions
>;

function hooks(queryClient: QueryClient, options?: Options) {
  function onError(err: any, added: ViewingRoom, context: ContextFunctions) {
    context?.forEach((item) => item());
    options?.onError && options.onError(err, added, context);
  }

  async function onSettled(
    data: any,
    error: any,
    variables: ViewingRoom,
    context: ContextFunctions
  ) {
    await Promise.all([
      queryClient.invalidateQueries([FOLLOWS_CACHE_KEY]),
      queryClient.invalidateQueries([VIEWING_ROOM_CACHE_KEY], {
        exact: false,
      }),
      queryClient.invalidateQueries([VIEWING_ROOM_CACHE_KEY_INFINITE], {
        exact: false,
      }),
    ]);
    options?.onSettled &&
      (await options?.onSettled(data, error, variables, context));
  }

  return { onError, onSettled };
}

async function optimisticList(
  item: ViewingRoom,
  queryClient: QueryClient,
  followed: boolean,
  state: any
) {
  await queryClient.cancelQueries([VIEWING_ROOM_CACHE_KEY, state]);
  const prev = queryClient.getQueryData<DefaultListResponse<ViewingRoom>>(
    [VIEWING_ROOM_CACHE_KEY, state],
    {
      active: true,
    }
  );

  if (prev) {
    const items = prev.items.map((i) => {
      if (i.id === item.id) {
        item.isFollowed = followed;
      }
      return i;
    });

    queryClient.setQueryData([VIEWING_ROOM_CACHE_KEY, state], {
      ...prev,
      items,
    });
  }
  return [
    () => queryClient.setQueryData([VIEWING_ROOM_CACHE_KEY, state], prev),
  ];
}

async function optimisticFair(
  item: ViewingRoom,
  followed: boolean,
  queryClient: QueryClient
) {
  const fairId = item.Section.Fair?.id.toString();
  if (!fairId) {
    return [];
  }

  const itemId = item.id.toString();

  await queryClient.cancelQueries([FAIR_QUERY_KEY, fairId]);

  const prevFair = queryClient.getQueryData<FairResponse>([
    FAIR_QUERY_KEY,
    fairId,
  ]);

  function callback(item: ViewingRoom) {
    if (item.id.toString() === itemId) {
      item.isFollowed = followed;
    }
    return item;
  }

  if (prevFair) {
    const sections = prevFair.sections.map(
      ({ notPromoted, promoted, ...rest }) => ({
        ...rest,
        promoted: promoted.map(callback),
        notPromoted: notPromoted.map(callback),
      })
    );

    queryClient.setQueryData<FairResponse>([FAIR_QUERY_KEY, fairId], {
      ...prevFair,
      sections,
    });
  }

  return [() => queryClient.setQueryData([FAIR_QUERY_KEY, fairId], prevFair)];
}

async function optimisticInfinite(
  item: ViewingRoom,
  queryClient: QueryClient,
  followed: boolean,
  state: any
) {
  await queryClient.cancelQueries([VIEWING_ROOM_CACHE_KEY_INFINITE]);

  const prev = queryClient.getQueryData<{
    pages: DefaultListResponse<ViewingRoom>[];
  }>([VIEWING_ROOM_CACHE_KEY_INFINITE, state], {
    active: true,
  });

  if (prev) {
    const pages = prev.pages.map((page) => {
      const items = page.items.map((i) => {
        if (i.id === item.id) {
          item.isFollowed = followed;
        }
        return i;
      });
      return { ...page, items };
    });
    queryClient.setQueryData([VIEWING_ROOM_CACHE_KEY_INFINITE, state], {
      ...prev,
      pages,
    });
  }
  return [
    () =>
      queryClient.setQueryData([VIEWING_ROOM_CACHE_KEY_INFINITE, state], prev),
  ];
}

const snackbarKey = "viewing-room-save";

export function useSaveViewingRoom(options?: Options) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const infiniteQuery = useViewingroomsQueryInfinite();
  const filters = useViewingroomsFilters();
  return useMutation(followViewingRoomRequest, {
    ...options,
    async onMutate(variables) {
      const prevPages = await optimisticInfinite(
        variables,
        queryClient,
        true,
        infiniteQuery
      );
      const prevList = await optimisticList(
        variables,
        queryClient,
        true,
        filters
      );
      const prevFair = await optimisticFair(variables, true, queryClient);
      await queryClient.cancelQueries([FOLLOWS_CACHE_KEY]);
      const previousFollowed = queryClient.getQueryData<FollowResponse>([
        FOLLOWS_CACHE_KEY,
      ]);

      await queryClient.cancelQueries([HOME_PAGE_QUERY_KEY]);
      const prevHomePage = queryClient.getQueryData<HomePageData>([
        HOME_PAGE_QUERY_KEY,
      ]);

      if (prevHomePage) {
        queryClient.setQueryData<HomePageData>([HOME_PAGE_QUERY_KEY], () => {
          return prevHomePage.map((item) => {
            if (isFairContent(item)) {
              return {
                ...item,
                sections: item.sections.map((section) => ({
                  ...section,
                  viewingRooms: setFollowForList(
                    variables,
                    section.viewingRooms
                  ),
                })),
              };
            }
            if (isSectionContent(item)) {
              return {
                ...item,
                viewingRooms: setFollowForList(variables, item.viewingRooms),
              };
            }
            return item;
          });
        });
      }

      if (previousFollowed) {
        queryClient.setQueryData<FollowResponse>([FOLLOWS_CACHE_KEY], (old) => {
          let response: FollowResponse = {
            viewingRooms: [],
            artworks: [],
          };
          if (!old) {
            return response;
          }
          response = { ...old };

          response.viewingRooms = [...response.viewingRooms, variables];
          return response;
        });
      }

      const {
        previousValue: prevVR,
      } = await optimisticHandler<ViewingRoomItem>(
        [VIEWING_ROOM_CACHE_KEY, variables.id.toString()],
        (item) => ({
          ...item!,
          isFollowed: true,
        }),
        queryClient
      );
      const mutateContext: ContextFunctions = options?.onMutate
        ? await options?.onMutate(variables)
        : [];

      enqueueSnackbar("Viewing room has been added", {
        preventDuplicates: true,
        timeout: 3000,
        key: snackbarKey,
      });
      return [
        () => {
          queryClient.setQueryData(
            [VIEWING_ROOM_CACHE_KEY, variables.id.toString()],
            prevVR
          );
        },
        () => {
          queryClient.setQueryData([FOLLOWS_CACHE_KEY], previousFollowed);
        },
        () => {
          queryClient.setQueryData([HOME_PAGE_QUERY_KEY], prevHomePage);
        },
        ...prevPages,
        ...prevList,
        ...prevFair,
        ...(mutateContext || []),
      ];
    },
    ...hooks(queryClient, options),
  });
}
export function useRemoveViewingRoomFromSaved(options?: Options) {
  const queryClient = useQueryClient();
  const infiniteQuery = useViewingroomsQueryInfinite();
  const filters = useViewingroomsFilters();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(unFollowViewingRoomRequest, {
    async onMutate(variables) {
      const prevPages = await optimisticInfinite(
        variables,
        queryClient,
        false,
        infiniteQuery
      );

      const prevList = await optimisticList(
        variables,
        queryClient,
        false,
        filters
      );

      const prevFair = await optimisticFair(variables, false, queryClient);
      await queryClient.cancelQueries([FOLLOWS_CACHE_KEY]);
      const previousFollowed = queryClient.getQueryData<FollowResponse>([
        FOLLOWS_CACHE_KEY,
      ]);

      queryClient.setQueryData<FollowResponse>([FOLLOWS_CACHE_KEY], (old) => {
        const response: FollowResponse = {
          viewingRooms: [],
          artworks: [],
        };
        if (!old) {
          return response;
        }
        response.viewingRooms = old.viewingRooms.filter(
          (item) => item.id !== variables.id
        );
        return response;
      });

      await queryClient.cancelQueries([HOME_PAGE_QUERY_KEY]);
      const prevHomePage = queryClient.getQueryData<HomePageData>([
        HOME_PAGE_QUERY_KEY,
      ]);

      if (prevHomePage) {
        queryClient.setQueryData<HomePageData>([HOME_PAGE_QUERY_KEY], () => {
          return prevHomePage.map((item) => {
            if (isFairContent(item)) {
              return {
                ...item,
                sections: item.sections.map((section) => ({
                  ...section,
                  viewingRooms: setFollowForList(
                    variables,
                    section.viewingRooms
                  ),
                })),
              };
            }
            if (isSectionContent(item)) {
              return {
                ...item,
                viewingRooms: setFollowForList(variables, item.viewingRooms),
              };
            }
            return item;
          });
        });
      }

      const {
        previousValue: prevVR,
      } = await optimisticHandler<ViewingRoomItem>(
        [VIEWING_ROOM_CACHE_KEY, variables.id.toString()],
        (item) => ({
          ...item!,
          isFollowed: false,
        }),
        queryClient
      );

      const mutateContext = options?.onMutate
        ? await options?.onMutate(variables)
        : [];
      enqueueSnackbar("Viewing room has been removed", {
        preventDuplicates: true,
        timeout: 3000,
        key: snackbarKey,
      });
      // return { previousFollowed, prevVR, prevHomePage, ...mutateContext };
      return [
        () => {
          queryClient.setQueryData(
            [VIEWING_ROOM_CACHE_KEY, variables.id.toString()],
            prevVR
          );
        },
        () => {
          queryClient.setQueryData([FOLLOWS_CACHE_KEY], previousFollowed);
        },
        () => {
          queryClient.setQueryData([HOME_PAGE_QUERY_KEY], prevHomePage);
        },
        ...prevPages,
        ...prevList,
        ...prevFair,
        ...(mutateContext || []),
      ];
    },
    ...hooks(queryClient, options),
  });
}
