import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { setActive, setViewingRoomId } from "../store/signTheBookStore";

export default function useSignTheBookBar() {
  const dispatch = useDispatch();
  const active = useSelector((state: RootState) => state.signTheBook.active);
  const viewingRoomId = useSelector((state: RootState) => state.signTheBook.viewingRoomId);

  const showBar = useCallback(() => {
    dispatch(setActive(true));
  }, [dispatch]);

  const hideBar = useCallback(() => {
    dispatch(setActive(false));
  }, [dispatch]);

  const addViewingRoomId = useCallback((vrId: ViewingRoom['id']) => {
    dispatch(setViewingRoomId(vrId));
  }, [dispatch]);

  const removeViewingRoomId = useCallback(() => {
    dispatch(setViewingRoomId(null));
  }, [dispatch]);

  return { active, showBar, viewingRoomId, hideBar, addViewingRoomId, removeViewingRoomId };
}
